.mortgage-calculator-wrapper{
    width:100%; 
    height:100vh;
    
}

.mortgage-calculator{
    border: none;
    display: block;
}

